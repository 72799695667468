body {
  font-family: sans-serif;
  padding: 20px;
  margin: 0;
}

h1 {
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 25px 0;
}

h2 {
  font-size: 24px;
  font-weight: normal;
  margin: 0 0 10px 0;
}

form {
  font-size: 21px;
  font-weight: bold;
  max-width: 720px;
  margin: 0 auto 20px auto;
}

.calc_result {
  font-size: 32px;
  line-height: 36px;
  vertical-align: middle;
}

input {
  height: 36px;
  width: 140px;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  box-sizing: border-box;
  vertical-align: middle;
  margin: 5px 0;
}

button {
  width: 140px;
  height: 36px;
  padding: 0 20px;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 16px;
  margin: 5px 0;
}

.image {
  display: block;
  position: relative;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
}

.image .title {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2em;
  color: #111;
}

.image img {
  margin: 0 auto;
  max-width: 100%;
  min-width: 240px;
  border-radius: 50%;
}

@media only screen and (min-width: 600px) {
  .image img {
    max-width: 540px;
  }
}

#tabs {
  max-width: 720px;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 auto 20px auto;
}

#tabs li {
  flex: 1 1 auto;
  border: 1px solid #ccc;
  border-left: none;
  text-align: center;
  padding: 5px 10px 6px 10px;
  cursor: pointer;
  font-size: 16px;
}

#tabs li:first-child {
  border: 1px solid #ccc;
}

#tabs li.active {
  background: #ccc;
}

.hidden {
  display: none;
}
